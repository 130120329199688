<script>
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Swal from "sweetalert2";
import axios from 'axios';
import Lottie from "@/components/widgets/lottie.vue";

export default {
    setup() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        return { Toast }
    },
    page: {
        title: "Contacts",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    components: {
        Layout,
        PageHeader,
        lottie: Lottie,
        // Multiselect
    },
    data() {
        return {
            title: "Agent",
            items: [{
                text: "Xmart",
                href: "/",
            },
            {
                text: "Contacts",
                active: true,
            },
            ],
            timeConfig: {
                enableTime: false,
                dateFormat: "d M, Y",
            },
            date2: null,
            defaultOptions: {
                animationData: animationData
            },
            defaultOptions1: {
                animationData: animationData1
            },
            searchQuery: null,
            page: 1,
            timeout: null,
            perPage: 10,
            pages: [],
            formAddCustomer: {
                name: '',
                email: '',
                username: '',
                phone: '',
                role: null,
                password: '',
                verfi_pass: ''
            },
            value: null,
            tagvalue: null,
            tagoption: [],
            contactsList: [],
            isEdit: false,
            fromValid: false,
            allFromValid: false,
            jabatan: [],
            roleSelect: ''
        };
    },
    computed: {
        displayedPosts() {
            return this.paginate(this.contactsList);
        },
        resultQuery() {
            if (this.searchQuery) {
                const search = this.searchQuery.toLowerCase();
                return this.displayedPosts.filter((data) => {
                    return (
                        data.name.toLowerCase().includes(search)
                    );
                });
            } else {
                return this.displayedPosts;
            }
        },
    },
    watch: {
        contactsList() {
            this.setPages();
        },
    },
    filters: {
        trimWords(value) {
            return value.split(" ").splice(0, 20).join(" ") + "...";
        },
    },
    methods: {
        showdetail(data) {
            console.log(data.name);
            document.getElementById('image').setAttribute('src', data.avatar)
            document.getElementById('name').innerHTML = data.name;
            document.getElementById('phoneno').innerHTML = data.phone;
            document.getElementById('tags').innerHTML = '';
            data.tags.forEach((tag) => {
                document.getElementById('tags').innerHTML += '&nbsp;<span class="badge badge-soft-primary">' + tag +
                    '</span>';
            })
        },
        setPages() {
            let numberOfPages = Math.ceil(this.contactsList.length / this.perPage);
            this.pages = [];
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        paginate(contactsList) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return contactsList.slice(from, to);
        },
        editdata(data) {
            var jabatanId = this.jabatan.find(el => {
                return el.nama === data.role;
            })

            this.isEdit = true;
            this.formAddCustomer.name = data.name
            this.formAddCustomer.email = data.email
            this.formAddCustomer.username = data.username
            this.formAddCustomer.phone = data.phone
            this.formAddCustomer.role = jabatanId.id

            document.getElementById("id").value = data.id
            document.getElementById('exampleModalLabel').innerHTML = "Update Agent";
            document.getElementById('edit-btn').style.display = 'block';
            document.getElementById('add-btn').style.display = 'none'
        },
        async updateorder() {

            var ClientID = process.env.VUE_APP_CLIENT_ID;
            var AccessToken = localStorage.getItem("jwt")

            var id = document.getElementById("id").value

            const saveEdited = await axios.post(process.env.VUE_APP_URL_API + "agent-api/updateAgent/"+id+"?ClientID=" + ClientID + "&AccessToken=" + AccessToken,
                JSON.stringify(this.formAddCustomer))
                .then((response) => {
                    return response
                }).catch((er) => {
                    return er
                });

            var responseStatusCode = saveEdited.data.Meta.Code
            var responseStatusMsg = saveEdited.data.Meta.Message

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            if (responseStatusCode != 200) {
                Toast.fire({
                    icon: 'error',
                    title: responseStatusMsg
                })
            } else {
                Toast.fire({
                    icon: 'success',
                    title: responseStatusMsg
                })
            }

            var agentListID = this.contactsList.findIndex(el => {
                return parseInt(el.id) === parseInt(id)
            })

            const dataResult = saveEdited.data.Data
            this.contactsList[agentListID].name = dataResult.Name
            this.contactsList[agentListID].email = dataResult.Email
            this.contactsList[agentListID].username = dataResult.Username
            this.contactsList[agentListID].phone = dataResult.Phone
            this.contactsList[agentListID].role = dataResult.Role.Title
            this.contactsList[agentListID].avatar = dataResult.Avatar ? dataResult.Avatar.Original : require('@/assets/images/users/user-dummy-img.jpg')

            document.getElementById('edtclosemodal').click();
            document.getElementById("addform").reset();
        },
        async addorder() {
            this.isEdit = false;

            var params = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
            const save = await axios.post(process.env.VUE_APP_URL_API+"agent-api/createAgent"+params, 
            JSON.stringify(this.formAddCustomer))

            var responseStatusCode = save.data.Meta.Code
            var responseStatusMsg = save.data.Meta.Message
            var responseData = save.data.Data

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            if (responseStatusCode != 200) {
                Toast.fire({
                    icon: 'error',
                    title: responseStatusMsg
                })
            } else {
                Toast.fire({
                    icon: 'success',
                    title: responseStatusMsg
                })

                this.contactsList.push({
                    id: responseData.ID,
                    contactId: "#VZ00" + this.contactsList.length,
                    name: responseData.Name,
                    email: responseData.Email,
                    username: responseData.Username,
                    phone: responseData.Phone,
                    role: responseData.Role.Title,
                    avatar: responseData.Avatar ? responseData.Avatar.Original : require('@/assets/images/users/user-dummy-img.jpg')
                })

                document.getElementById('edtclosemodal').click();
                document.getElementById("addform").reset();
            }

        },
        deletedata(event) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Yes, delete it!",
            }).then( async (result) => {
                if (result.value) {
                    const listDeleted = this.contactsList[this.contactsList.indexOf(event)];
                    var url = process.env.VUE_APP_URL_API+"agent-api/deleteAgent/"+listDeleted.id+
                    "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");

                    const deleteSave = await axios.get(url);

                    if(deleteSave.data.Data){
                        this.contactsList.splice(this.contactsList.indexOf(event), 1);
                        Swal.fire("Deleted!", "Your file has been deleted.", "success");
                    }
                }
            });
            // console.log(validasi.getInput());
            // console.log(event);
        },
        addnew() {
            this.isEdit = false;
            this.formAddCustomer.name = "";
            this.formAddCustomer.email = "";
            this.formAddCustomer.phone = "";
            this.formAddCustomer.username = "";
            this.formAddCustomer.role = "";
            this.formAddCustomer.password = "";
            this.formAddCustomer.verfi_pass = "";

            document.getElementById("addform").reset();
            document.getElementById('exampleModalLabel').innerHTML = "Add Agent";
            document.getElementById('add-btn').style.display = 'block';
            document.getElementById('edit-btn').style.display = 'none';
        },
        validatePass(){
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(() => {
                if(self.formAddCustomer.verfi_pass === self.formAddCustomer.password){
                    self.fromValid = true
                } else {
                    self.fromValid = false
                }
            }, 500);
        },
        validateFrom(){
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(() => {
                if(self.formAddCustomer.name !==  "" || self.formAddCustomer.email !== "" || self.formAddCustomer.phone !== ""
                || self.formAddCustomer.username !== "" || self.formAddCustomer.role !== "" || self.formAddCustomer.password !== ""){
                    this.allFromValid = true;
                }
            }, 500);
        },
        deleteMultiple() {
            let ids_array = [];
            var items = document.getElementsByName("chk_child");
            items.forEach(function (ele) {
                if (ele.checked == true) {
                    var trNode = ele.parentNode.parentNode.parentNode;
                    var id = trNode.querySelector(".id a").innerHTML;
                    ids_array.push(id);
                }
            });
            if (typeof ids_array !== "undefined" && ids_array.length > 0) {
                if (confirm("Are you sure you want to delete this?")) {
                    var cusList = this.contactsList;
                    ids_array.forEach(function (id) {
                        cusList = cusList.filter(function (customer) {
                            return customer._id != id;
                        });
                    });
                    this.contactsList = cusList;
                    document.getElementById("checkAll").checked = false;
                    var itemss = document.getElementsByName("chk_child");
                    itemss.forEach(function (ele) {
                        if (ele.checked == true) {
                            ele.checked = false
                            ele.closest("tr").classList.remove("table-active");
                        }
                    });
                } else {
                    return false;
                }
            } else {
                Swal.fire({
                    title: "Please select at least one checkbox",
                    confirmButtonClass: "btn btn-info",
                    buttonsStyling: false,
                    showCloseButton: true,
                });
            }
        },
        redirectToChat(phone) {
            this.$router.push({
                path: '/chat/' + phone
            });
        },
        async setStatusActive(agentID, e)
        {
            var status = 0;
            if(e.target.checked){
                status = 1;
            }

            let formData = new FormData();
            formData.append("AgentID", agentID);
            formData.append("Status", status);

            let secureParam = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt")
            let url = process.env.VUE_APP_URL_API+"agent-api/setStatus"+secureParam;
            const reqUbahStatus = await axios.post(url, formData);
            if(reqUbahStatus.status == 200){
                let data = reqUbahStatus.data;
                
                let opt = {
                    title: data.Meta.Message
                }

                if(data.Meta.Code == 200){
                    opt['icon'] = 'success';
                } else {
                    opt['icon'] = 'error'
                }

                this.Toast.fire(opt);
            } else {
                this.Toast.fire({
                    title: reqUbahStatus.statusText,
                    icon: 'error'
                })
            }
        }
    },
    beforeMount() {

        var baseUrl = process.env.VUE_APP_URL_API;
        var url = baseUrl + "agent-api/getAgentList?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt") +"&All=true"

        axios.get(url).then((data) => {
            const res = data.data;
            if (res.Meta.Code == 200) {
                if(res.Data.length){
                    res.Data.forEach((item, index) => {
                        this.contactsList.push({
                            id: item.ID,
                            contactId: "#VZ00" + index,
                            name: item.Name,
                            statusActive: item.StatusActive,
                            lastOn: item.LastActiveOn,
                            lastOff: item.LastActiveOff,
                            email: item.Email,
                            username: item.Username,
                            phone: item.Phone,
                            role: item.Role.Title,
                            divisi: item.Divisi.Title ?? "No Handle Divisi",
                            avatar: item.Avatar ? item.Avatar.Original : require('@/assets/images/users/user-dummy-img.jpg')
                        })
                    })
                    this.setPages();
                }
            }
        }).catch((er) => {
            console.log(er)
        });

        var urlGetJabatan = process.env.VUE_APP_BASE_URL+"api-helper/getAllJabatan"

        axios.get(urlGetJabatan).then((result) => {
            result.data.forEach(item => {
                this.jabatan.push({
                    id: item.ID,
                    code: item.Code,
                    nama: item.Title
                })
            })
        })
    },
    mounted() {
        var checkAll = document.getElementById("checkAll");
        if (checkAll) {
            checkAll.onclick = function () {
                var checkboxes = document.querySelectorAll(
                    '.form-check-all input[type="checkbox"]'
                );
                if (checkAll.checked == true) {
                    checkboxes.forEach(function (checkbox) {
                        checkbox.checked = true;
                        checkbox.closest("tr").classList.add("table-active");
                    });
                } else {
                    checkboxes.forEach(function (checkbox) {
                        checkbox.checked = false;
                        checkbox.closest("tr").classList.remove("table-active");
                    });
                }
            };
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center flex-wrap gap-2">
                            <div class="flex-grow-1">
                                <button class="btn btn-info add-btn me-1" data-bs-toggle="modal" href="#showmodal"
                                    @click="addnew">
                                    <i class="ri-add-fill me-1 align-bottom"></i> Add Agent
                                </button>
                                <button class="btn btn-soft-danger" @click="deleteMultiple">
                                    <i class="ri-delete-bin-2-line"></i>
                                </button>
                            </div>
                            <!--
                            <div class="flex-shrink-0">
                                <div class="hstack text-nowrap gap-2">
                                    <button class="btn btn-danger" data-bs-toggle="modal">
                                        <i class="ri-filter-2-line me-1 align-bottom"></i> Filters
                                    </button>
                                    <button class="btn btn-soft-success shadow-none">Import</button>
                                    <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown"
                                        aria-expanded="false" class="btn btn-soft-info shadow-none">
                                        <i class="ri-more-2-fill"></i>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                        <li><a class="dropdown-item" href="#">All</a></li>
                                        <li><a class="dropdown-item" href="#">Last Week</a></li>
                                        <li><a class="dropdown-item" href="#">Last Month</a></li>
                                        <li><a class="dropdown-item" href="#">Last Year</a></li>
                                    </ul>
                                </div>
                            </div>
                            -->
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-xxl-9">
                <div class="card" id="contactList">
                    <div class="card-header">
                        <div class="row g-3">
                            <div class="col-md-4">
                                <div class="search-box">
                                    <input v-model="searchQuery" type="text" class="form-control search"
                                        placeholder="Search for contact..." />
                                    <i class="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <!--
                            <div class="col-md-auto ms-auto">
                                <div class="d-flex align-items-center gap-2">
                                    <span class="text-muted flex-shrink-0">Sort by: </span>
                                    <Multiselect class="form-control" style="width:150px;" v-model="value"
                                        :close-on-select="true" :searchable="true" :create-option="true" :options="[
                            { value: 'Name', label: 'Name' },
                            { value: 'Company', label: 'Company' },
                            { value: 'Lead', label: 'Lead' }
                          ]" />
                                </div>
                            </div>
                            -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="table-responsive table-card mb-3">
                                <table class="table align-middle table-nowrap mb-0" id="customerTable">
                                    <thead class="table-light">
                                        <tr>
                                            <th scope="col" style="width: 50px">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="checkAll"
                                                        value="option" />
                                                </div>
                                            </th>
                                            <th class="sort" data-sort="name" scope="col">Name</th>
                                            <th class="sort" data-sort="phone" scope="col">
                                                Email
                                            </th>
                                            <th class="sort" scope="col">
                                                Status
                                            </th>
                                            <th class="sort" scope="col">
                                                Time Status Change
                                            </th>
                                            <th class="sort" data-sort="Designation" scope="col">
                                                Username
                                            </th>
                                            <th class="sort" data-sort="Designation" scope="col">
                                                Phone
                                            </th>
                                            <th class="sort" data-sort="Designation" scope="col">
                                                Role
                                            </th>
                                            <th class="sort" data-sort="Designation" scope="col">
                                                Divisi
                                            </th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="list form-check-all">
                                        <tr v-for="(data, index) of resultQuery" :key="index">
                                            <th scope="row">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="chk_child"
                                                        value="option1" />
                                                </div>
                                            </th>
                                            <td class="id" style="display: none">
                                                <a href="javascript:void(0);" class="fw-medium link-primary">{{ data.id
                                                    }}</a>
                                            </td>
                                            <td class="">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-shrink-0"><img :src="data.avatar" alt=""
                                                            class="user_image avatar-xs rounded-circle">
                                                    </div>
                                                    <div class="flex-grow-1 ms-2 name">{{ data.name }}</div>
                                                </div>
                                            </td>
                                            <td class="phone">{{ data.email }}</td>
                                            <td class="status text-center">
                                                <div class="form-check form-switch form-switch-success">
                                                    <label class="form-check-label me-2" :for="'flexSwitchCheckChecked-'+index">Off</label>
                                                    <input class="form-check-input" :name="'statusActive'+index" type="checkbox" role="switch" :id="'flexSwitchCheckChecked-'+index" :checked="data.statusActive === 'on'" @change="setStatusActive(data.id, $event)">
                                                    <label class="form-check-label ms-2" :for="'flexSwitchCheckChecked-'+index">On</label>
                                                </div>
                                            </td>
                                            <td class="tags">
                                                <table>
                                                    <tr>
                                                        <td>Last On</td>
                                                        <td>: {{ data.lastOn ?? '-' }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last Off</td>
                                                        <td>: {{ data.lastOff ?? '-' }}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td class="tags">{{ data.username }}</td>
                                            <td class="tags">{{ data.telp }}</td>
                                            <td class="tags">{{ data.role }}</td>
                                            <td class="tags">{{ data.divisi }}</td>
                                            <td>
                                                <ul class="list-inline hstack gap-2 mb-0">
                                                    <li class="list-inline-item">
                                                        <div class="dropdown">
                                                            <button class="btn btn-soft-secondary btn-sm dropdown"
                                                                type="button" data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                <i class="ri-more-fill align-middle"></i>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-end">
                                                                <!-- <li>
                                                                    <a class="dropdown-item" href="javascript:void(0);"
                                                                        @click="showdetail(data)"><i
                                                                            class="ri-eye-fill align-bottom me-2 text-muted"></i>
                                                                        View</a>
                                                                </li> -->
                                                                <li>
                                                                    <a class="dropdown-item edit-item-btn"
                                                                        data-bs-toggle="modal" href="#showmodal"
                                                                        @click="editdata(data)">
                                                                        <i
                                                                            class="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                                        Edit</a>
                                                                </li>
                                                                <li>
                                                                    <a class="dropdown-item remove-item-btn"
                                                                        @click="deletedata(data)">
                                                                        <i
                                                                            class="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                                                        Delete
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="noresult" style="display: none">
                                    <div class="text-center">
                                        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a"
                                            :options="defaultOptions" :height="75" :width="75" />
                                        <h5 class="mt-2">Sorry! No Result Found</h5>
                                        <p class="text-muted mb-0">
                                            We've searched more than 150+ Orders We did not find any
                                            orders for you search.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mt-3">
                                <div class="pagination-wrap hstack gap-2">
                                    <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                        @click="page--">
                                        Previous
                                    </a>
                                    <ul class="pagination listjs-pagination mb-0">
                                        <li :class="{
                                            active: pageNumber == page,
                                            disabled: pageNumber == '...',
                                        }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                                            @click="page = pageNumber">
                                            <a class="page" href="#">{{ pageNumber }}</a>
                                        </li>
                                    </ul>
                                    <a class="page-item pagination-next" href="#" @click="page++"
                                        v-if="page < pages.length">
                                        Next
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <!--end card-->
            </div>
            <!--end col-->
            
            <!-- <div class="col-xxl-3">
                <div class="card" id="contact-view-detail">
                    <div class="card-body text-center">
                        <div class="position-relative d-inline-block">
                            <img src="@/assets/images/users/avatar-10.jpg" id="image" alt=""
                                class="avatar-lg rounded-circle img-thumbnail">
                            <span class="contact-active position-absolute rounded-circle bg-success"><span
                                    class="visually-hidden"></span></span>
                        </div>
                        <h5 class="mt-4 mb-1" id="name">Tonya Noble</h5>
                        <p class="text-muted" id="company">Nesta Technologies</p>

                        <ul class="list-inline mb-0">
                            <li class="list-inline-item avatar-xs">
                                <a href="javascript:void(0);"
                                    class="avatar-title bg-soft-success text-success fs-15 rounded">
                                    <i class="ri-phone-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs">
                                <a href="javascript:void(0);"
                                    class="avatar-title bg-soft-danger text-danger fs-15 rounded">
                                    <i class="ri-mail-line"></i>
                                </a>
                            </li>
                            <li class="list-inline-item avatar-xs">
                                <a href="javascript:void(0);"
                                    class="avatar-title bg-soft-warning text-warning fs-15 rounded">
                                    <i class="ri-question-answer-line"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <h6 class="text-muted text-uppercase fw-semibold mb-3">Personal Information</h6>
                        <p class="text-muted mb-4">Hello, I'm Tonya Noble, The most effective objective is
                            one that is tailored to the job you are applying for. It states what kind of
                            career you are seeking, and what skills and experiences.</p>
                        <div class="table-responsive table-card">
                            <table class="table table-borderless mb-0">
                                <tbody>
                                    <tr>
                                        <td class="fw-medium" scope="row">Phone No</td>
                                        <td id="phoneno">414-453-5725</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-medium" scope="row">Lead Score</td>
                                        <td id="leadscoreno">154</td>
                                    </tr>
                                    <tr>
                                        <td class="fw-medium" scope="row">Tags</td>
                                        <td id="tags">
                                            <span class="badge badge-soft-primary">Lead</span>
                                            <span class="badge badge-soft-primary">Partner</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
        <!--end row-->
        <div class="modal fade" ref="modalsForm" id="showmodal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0">
                    <div class="modal-header bg-soft-info p-3">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="close-modal"></button>
                    </div>
                    <form action="" id="addform">
                        <div class="modal-body">
                            <input type="hidden" id="id" />
                            <div class="row g-3">
                                <div class="col-lg-12">
                                    <div>
                                        <label for="name-field" class="form-label">Name</label>
                                        <input v-model="this.formAddCustomer.name" type="text" id="name"
                                            class="form-control" @keyup="validateFrom" placeholder="Enter Name" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div>
                                        <label for="company_name-field" class="form-label">Email</label>
                                        <input v-model="this.formAddCustomer.email" type="text" id="email"
                                            class="form-control" @keyup="validateFrom" placeholder="Enter Email" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div>
                                        <label for="company_name-field" class="form-label">Username</label>
                                        <input v-model="this.formAddCustomer.username" type="text" id="username"
                                            class="form-control" @keyup="validateFrom" placeholder="Enter Username" required />
                                    </div>
                                </div>
                                <div v-show="!this.isEdit" class="col-lg-6">
                                    <div>
                                        <label for="phone-field" class="form-label">Password</label>
                                        <input v-model="this.formAddCustomer.password" type="password" id="password"
                                            class="form-control" @keyup="validateFrom" placeholder="Enter Password" required />
                                    </div>
                                </div>
                                <div v-show="!this.isEdit" class="col-lg-6">
                                    <div>
                                        <label for="phone-field" class="form-label">Verif Password</label>
                                        <input v-model="this.formAddCustomer.verfi_pass" type="password" id="verfi_pass"
                                            class="form-control" @keyup="validatePass" :class="{'is-invalid': !this.fromValid}" placeholder="Enter Verfi Password" required />
                                        <div class="invalid-feedback">Password no correct</div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div>
                                        <label for="phone-field" class="form-label">Phone</label>
                                        <input v-model="this.formAddCustomer.phone" type="text" id="phone"
                                            class="form-control" @keyup="validateFrom" placeholder="Enter phone no" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div>
                                        <label for="phone-field" class="form-label">Role</label>
                                        <select v-model="this.formAddCustomer.role" @change="validateFrom" class="form-select mb-3" aria-label="Default select example" required>
                                            <option selected>Open this select menu</option>
                                            <option v-for="item of jabatan" :value="item.id" :key="item.id">{{item.nama}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="hstack gap-2 justify-content-end">
                                <button type="button" class="btn btn-light" data-bs-dismiss="modal"
                                    id="edtclosemodal">Close</button>
                                <button type="button" class="btn btn-success" :class="{disabled: (!this.fromValid || !this.allFromValid)}" id="add-btn" @click="addorder">
                                    Add Agent
                                </button>
                                <button type="button" class="btn btn-warning" id="edit-btn"
                                    @click="updateorder()">Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>